<template>
  <b-card>
    <b-card-title>1. 计算方式  <small class="text-muted">用于展现一个车手的竞技水平</small></b-card-title>
    <b-card-text>
      HiRating的算法依据
      <b-link
        href="https://en.wikipedia.org/wiki/Elo_rating_system"
        target="_blank"
      >
        ELO Rating System
      </b-link>
      算法。ELO等级分制度是指由匈牙利裔美国物理学家Arpad Elo创建的一个衡量各类对弈活动水平的评价方法，是当今对弈水平评估的公认的权威方法。被广泛用于国际象棋、围棋、足球、篮球等运动。网络游戏英雄联盟、魔兽世界内的竞技对战系统也采用此分级制度（即天梯系统）。
    </b-card-text>
    <b-card-text>
      具体算法可以参考上述链接，算法计算基于本场参赛的所有车手的HiRating平均值。结果测算验证，我们对该算法中的常量取值：
    </b-card-text>
    <ul>
      <li>一个新车手的初始HiRating为1500分</li>
      <li>K值取100</li>
      <li>S<sub>A</sub>值为0~1，第一名为1，最后一名为0，中间名次者依据排名/参赛人数计算</li>
    </ul>
    <b-card-text>
      ELO算法的通俗解释：
    </b-card-text>
    <ul>
      <li>水平高的车手，哪怕没有在HiPole的参赛历史，他通过几场高水平的赛事击败了大神，他的HiRating值会迅速提升</li>
      <li>水平高的车手，在HiPole有辉煌的参赛历史，有一天心血来潮参加了低水平的赛事，但是拿到的倒数名次，他的HiRating值下降幅度会大于同场的其他车手</li>
      <li>发展中的车手，通过勤勤恳恳的参赛，只要每场排名前50%，都会获得不等的HiRating值的增加</li>
      <li>以围棋打个比方，Alpha Go与人类的对战历史并不久，但是通过击败李世石、柯洁等世界顶尖棋手，一举晋升世界第二。</li>
    </ul>
    <b-card-title>2. 数据更新</b-card-title>
    <b-card-text>
      每当车手完成一场赛事后，程序将根据车手在赛场上的车手HiRating数据与比赛结果重新计算HiRating分。
    </b-card-text>
    <b-card-title>3. 展现方式</b-card-title>
    <b-card-text>
      <span>车手的安全分可以在车手详情、所有车手处看到。视觉效果为：HiRating: </span>
      <span class="text-warning">1654</span>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#' },
        { key: 'how', label: '条件' },
        { key: 'rewards', label: '获得分值' },
      ],
      items: [
        { id: 1, how: '参赛', rewards: '+10分' },
        { id: 2, how: '完赛', rewards: '+20分' },
        { id: 3, how: '获得积分', rewards: '+2分/每积分' },
        { id: 4, how: '正赛最快圈', rewards: '+5分' },
        { id: 5, how: '杆位', rewards: '+10分' },
        { id: 6, how: '里程', rewards: '+1分/每10KM' },
      ],
    }
  },
}
</script>
