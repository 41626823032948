<template>
  <b-card>
    <b-card-title>1. 计算方式  <small class="text-muted">综合车手各项数据后的分组</small></b-card-title>
    <b-card-text>
      目前赛照一共分为6档：
    </b-card-text>
    <b-table
      responsive
      :items="items"
      :fields="fields"
    />
    <b-card-text>
      对赛照有影响的两个因素
    </b-card-text>
    <ul>
      <li>如果车手的安全分小于6.0，根据一定的算法，赛照有可能会降一级。</li>
      <li>如果参加3场比赛以上且HiRating多于1500的，根据一定的算法，赛照有可能会升一级。</li>
    </ul>
    <b-card-title>2. 展现方式</b-card-title>
    <b-card-text>
      车手的赛照可以在车手详情、所有车手、车手赛照名单处看到。视觉效果为：
      <b-img
        height="24"
        class="ml-1"
        src="../../assets/images/license/A-24.png"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BTable, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BTable,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#' },
        { key: 'license', label: '赛照' },
        { key: 'how', label: '分组依据' },
      ],
      items: [
        { id: 1, license: 'Pro', how: '2017年9月1日起，完赛后HiRating排在所有车手前十的。终身制。' },
        { id: 2, license: 'A', how: '参赛经验等级在Lv20以上的车手' },
        { id: 3, license: 'B', how: '参赛经验等级在Lv10 - Lv20的车手' },
        { id: 4, license: 'C', how: '参赛经验等级在Lv5 - Lv10的车手' },
        { id: 5, license: 'D', how: '参赛经验等级在Lv2 - Lv5的车手' },
        { id: 6, license: 'R', how: '参赛经验等级在Lv2以内的车手' },
      ],
    }
  },
}
</script>
