<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="CornerRightUpIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">经验等级</span>
      </template>

      <AlgoExp />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="StarIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">安全分</span>
      </template>

      <AlgoSafety />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="TrendingUpIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">HiRating</span>
      </template>

      <AlgoRating />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="SidebarIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">赛照</span>
      </template>

      <AlgoLicense />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="TrelloIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">驾照分</span>
      </template>

      <AlgoDemerit />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AlgoExp from './algoExp.vue'
import AlgoSafety from './algoSafety.vue'
import AlgoRating from './algoRating.vue'
import AlgoLicense from './algoLicense.vue'
import AlgoDemerit from './algoDemerit.vue'

export default {
  components: {
    BTabs,
    BTab,
    AlgoExp,
    AlgoSafety,
    AlgoRating,
    AlgoLicense,
    AlgoDemerit,
  },
  data() {
    return {
      options: {},
    }
  },
}
</script>
