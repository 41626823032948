<template>
  <b-card>
    <b-card-title>1. 计算方式  <small class="text-muted">用于展现一个车手的驾驶安全评级</small></b-card-title>
    <b-card-text>
      所有的安全分计算都基于两个因素：碰撞与里程。模拟赛车里的碰撞大致分三种:
    </b-card-text>
    <ul>
      <li>与他人碰撞</li>
      <li>被他人碰撞</li>
      <li>单车事故，比如与墙体、赛道上的零件等</li>
    </ul>
    <b-card-text>
      在安全分的计算中，我们只取上述的第一种情况的数值，即车手与他人发生的碰撞。
    </b-card-text>
    <b-card-text>
      对于单次碰撞，有一个车辆损伤程度（碰撞）力度的取值，我们采用0~1的区间。0即为趋向于无穷小的刮擦，1则为全力碰撞，以Automobilista为例，1的碰撞意味着很高的车损以至于无法正常驾驶。
    </b-card-text>
    <b-card-text>
      基于以上理解，我们的安全分计算方式为：
    </b-card-text>
    <b-card-text class="text-center">
      <span class="text-info font-weight-bolder">总危害</span><span> = （碰撞总数 + 碰撞总车损 X 10） / 总里程</span>
    </b-card-text>
    <b-card-text class="text-center">
      <span class="text-info font-weight-bolder">安全星级</span><span> = （ 100 - 总危害 ) / 10</span>
    </b-card-text>
    <b-card-title>2. 数据更新</b-card-title>
    <b-card-text>
      每当车手完成一场赛事后，程序将根据车手在赛场上的碰撞数据重新计算生涯安全分。
    </b-card-text>
    <b-card-title>3. 展现方式</b-card-title>
    <b-card-text>
      <span>车手的安全分可以在车手详情处看到。视觉效果为：安全分: </span>
      <font-awesome-icon
        class="text-primary"
        icon="star"
      />
      <font-awesome-icon
        class="text-primary"
        icon="star"
      />
      <font-awesome-icon
        class="text-primary"
        icon="star"
      />
      <font-awesome-icon
        class="text-primary"
        icon="star"
      />
      <font-awesome-icon
        class="text-secondary"
        icon="star"
      />
      <span class="text-warning"> (8.1)</span>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStarHalfAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Ripple from 'vue-ripple-directive'

library.add(faStarHalfAlt, faStar)

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    FontAwesomeIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#' },
        { key: 'how', label: '条件' },
        { key: 'rewards', label: '获得分值' },
      ],
      items: [
        { id: 1, how: '参赛', rewards: '+10分' },
        { id: 2, how: '完赛', rewards: '+20分' },
        { id: 3, how: '获得积分', rewards: '+2分/每积分' },
        { id: 4, how: '正赛最快圈', rewards: '+5分' },
        { id: 5, how: '杆位', rewards: '+10分' },
        { id: 6, how: '里程', rewards: '+1分/每10KM' },
      ],
    }
  },
}
</script>
