<template>
  <b-card>
    <b-card-title>1. 计算方式  <small class="text-muted">体现一个车手因为赛道事故的扣分</small></b-card-title>
    <b-card-text>
      与安全分不同，安全分体现了一个车手的安全性与避免碰撞的控车能力。但是对于不得不产生接触的赛道情况下，需要对事故的责任双方进行判定，我们可以用现实生活中的驾照扣分来类比，同时FIA等汽联、赛事都有驾照分的机制。
    </b-card-text>
    <ul>
      <li>每个记分周期内，每位车手有12分</li>
      <li>记分周期计算为12个自然月，或者20场比赛，先到为准</li>
      <li>当扣满12分后，则在记分周期内禁止参加联赛</li>
      <li>扣分情况仅产生于赛后车手投诉或者仲裁委员会调查</li>
      <li>根据竞技规则，不同的赛事投诉的处罚等级对应不同的驾照分的扣除</li>
    </ul>
    <b-card-title>2. 展现方式</b-card-title>
    <b-card-text>
      车手们可以在车手详情页、驾照分历史页等页面看到驾照分的相关信息。
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#' },
        { key: 'how', label: '条件' },
        { key: 'rewards', label: '获得分值' },
      ],
      items: [
        { id: 1, how: '参赛', rewards: '+10分' },
        { id: 2, how: '完赛', rewards: '+20分' },
        { id: 3, how: '获得积分', rewards: '+2分/每积分' },
        { id: 4, how: '正赛最快圈', rewards: '+5分' },
        { id: 5, how: '杆位', rewards: '+10分' },
        { id: 6, how: '里程', rewards: '+1分/每10KM' },
      ],
    }
  },
}
</script>
