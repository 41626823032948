<template>
  <b-card>
    <b-card-title>1. 计算方式  <small class="text-muted">用于展现一个车手的活跃度</small></b-card-title>
    <b-card-text>
      车手每参加一场赛事，根据其比赛结果，依据下述计算方法计算车手的本场获得的经验值（Exp）：
    </b-card-text>
    <b-table
      responsive
      :items="items"
      :fields="fields"
    />
    <b-card-title>2. 升级方式</b-card-title>
    <b-card-text>
      每当车手积分增加100分，则升一级。比如车手Hamilton的经验值为1422分，则他的经验等级为Lv14。
    </b-card-text>
    <b-card-title>3. 展现方式</b-card-title>
    <b-card-text>
      车手的经验等级可以在车手详情、所有车手等处看到。视觉效果为：Lv.
      <b-avatar
        size="sm"
        variant="light-warning"
      >
        <span>18</span>
      </b-avatar>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BTable, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BTable,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#' },
        { key: 'how', label: '条件' },
        { key: 'rewards', label: '获得分值' },
      ],
      items: [
        { id: 1, how: '参赛', rewards: '+10分' },
        { id: 2, how: '完赛', rewards: '+20分' },
        { id: 3, how: '获得积分', rewards: '+2分/每积分' },
        { id: 4, how: '正赛最快圈', rewards: '+5分' },
        { id: 5, how: '杆位', rewards: '+10分' },
        { id: 6, how: '里程', rewards: '+1分/每10KM' },
      ],
    }
  },
}
</script>
